<template>
  <tr>
    <td v-if="config.config.fieldsSelected" class="text-xs-center" style="min-width: 50px !important;max-width: 50px !important;text-align: center;display: flex;justify-content: center;align-items: center;">
      <v-checkbox v-model="props.props.selected" primary hide-details></v-checkbox>
    </td>
    <td class="text-xs-center" style="padding: 0px !important; min-width: 50px;">
      <v-menu v-if="config.mode != 'select'" bottom left>
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on" >
            <v-icon>settings</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-tile
            @click="$WiEditDialog({wiConfig: config.store, onSubmit: config.refresh, data: props.item})" 
            v-prms="{'id': 'dd919a8b', mode: 'disabled'}"
          >
            <v-list-tile-avatar>
              <v-avatar>
                <v-icon color="orange">edit</v-icon>
              </v-avatar>
            </v-list-tile-avatar>
            <v-list-tile-title>Editar canal</v-list-tile-title>
          </v-list-tile>
          <v-divider></v-divider>
          <v-list-tile @click="$WiListDialog({wiConfig: 'talk-channel-person', filter: { talk_channel_id: props.item.id }, wiEditData: { talk_channel_id: props.item.id }, callback: config.refresh})">
            <v-list-tile-avatar>
              <v-avatar>
                <v-icon color="blue">person</v-icon>
              </v-avatar>
            </v-list-tile-avatar>
            <v-list-tile-title>Colaboradores</v-list-tile-title>
          </v-list-tile>
          <v-divider></v-divider>
          <v-list-tile @click="updateGroups()" :loading="statusLoading">
            <v-list-tile-avatar>
              <v-avatar>
                <v-icon color="green">groups</v-icon>
              </v-avatar>
            </v-list-tile-avatar>
            <v-list-tile-title>Atualizar/buscar Grupos</v-list-tile-title>
          </v-list-tile>
        </v-list>
      </v-menu>
      <v-btn v-else flat icon outline color="green" @click="config.select(props.item)" class="mx-0">
        <v-icon large outlined>done</v-icon>
      </v-btn>
    </td>
    <td class="text-xs-center" style="padding: 0px !important; min-width: 30px;">
      <span>{{ props.item.id }}</span>
    </td>
    <td class="text-xs-left" style="padding: 0 10px !important; max-width: 150px;">
      <div style="text-align: center;">
        <img v-if="props.item.type.code == 'wpp'" src="static/icons/monitor/whatsapp.png" style="width: 30px;">
        <br>
        <strong v-text="props.item.type.name"></strong>
      </div>
    </td>
    <td class="text-xs-left" style="padding: 0 10px !important; max-width: 150px;">
      <v-chip large :color="props.item.color">
        <strong v-text="props.item.name" :style="{color: $WiColorContrast(props.item.color)}"></strong>
      </v-chip>
    </td>
    <td class="text-xs-left" style="padding: 0 10px !important; max-width: 150px;">
      <strong v-if="props.item.recipient" v-text="props.item.recipient"></strong>
      <strong v-else>- - -</strong>
      <span v-if="props.item.push_name">
        <br>
        <span v-text="props.item.push_name"></span>
      </span>
    </td>
    <td class="text-xs-center" style="padding: 0 10px !important; max-width: 150px;">
      <strong style="display: flex; align-content: center; flex-direction: column;">
        <v-icon :color="channel.status.color">
          {{ channel.status.icon }}
        </v-icon>
        <span>{{ channel.status.name }}</span>
      </strong>
    </td>
    <td v-if="config.mode != 'select'" class="justify-center">
      <WhatsAppQRCode
        v-if="props.item.provider_id != 3 && !connected"
        :channel="props.item"
        :refresh="config.refresh"
        @refreshStatus="refreshStatus"
      ></WhatsAppQRCode>
      <v-btn v-if="false" disabled outline color="primary" small link :to="{name: 'talk-channel-whatsapp-import-contacts', params: {id: channel.id}}">
        <v-icon>swap_horiz</v-icon>
        <span>Importar Contatos</span>
      </v-btn>
      <v-btn v-if="props.item.provider_id != 3 && connected" outline :loading="disconectLoading" color="primary" small @click="disconectChannel()">
        <v-icon>do_disturb_on</v-icon>
        <span>Desconectar</span>
      </v-btn>
      <!-- <v-btn icon @click="refreshStatus()" :loading="statusLoading">
        <v-icon>refresh</v-icon>
      </v-btn> -->
      <v-btn @click="refreshStatus()" :loading="statusLoading" outline color="primary" small>
        <v-icon>refresh</v-icon>
        <span>Verificar status</span>
      </v-btn>
      <!-- <v-tooltip v-if="props.item.provider_id != 3 && connected" bottom lazy>
        <template v-slot:activator="{ on }">
          <v-btn
            v-on="on" icon small
            @click="updateGroups()"
            :loading="statusLoading"
          >
            <v-icon>groups</v-icon>
          </v-btn>
        </template>
        <span>
          Atualizar/buscar Grupos
        </span>
      </v-tooltip> -->
      <v-tooltip v-if="props.item.provider_id != 3 && !connected" bottom lazy>
        <template v-slot:activator="{ on }">
          <v-btn
            icon small v-on="on"
            :loading="disconectLoading"
            @click="disconectChannel()"
          >
            <v-icon color="red">delete_sweep</v-icon>
          </v-btn>
        </template>
        <span>
          Reiniciar Conexão
        </span>
      </v-tooltip>
    </td>
  </tr>
</template>
<script>
  import WhatsAppQRCode from './WhatsAppQRCode'
  export default {
    name: 'TalkChannels',
    data: () => ({
      qrCodeUrl: null,
      qrCodeDialog: false,
      statusLoading: false,
      disconectLoading: false
    }),
    computed: {
      channel () {
        return this.props.item
      },
      connected () {
        return this.channel?.status?.code === 'connected'
      }
    },
    methods: {
      openTicket: function (ticketId) {
        this.$router.push({name: 'ticket', params: {id: ticketId}})
      },
      goToRouteDetail: function (talkId) {
        this.$router.push({name: 'talk', params: {id: talkId}})
      },
      getWhatsAppQrCode: function () {
        this.$WiApiGet({uri: `talks/channel/${this.channel.id}/connect`, callback: response => {
          if (response.success) {
            this.qrCodeUrl = response.success.data.url
          }
        }})
      },
      disconectChannel: function () {
        this.$WiApiPost({
          uri: `talks/channel/${this.channel.id}/disconect`,
          config: {msgAsk: true, msgLoad: true, msgSucess: true, msgError: true},
          callback: () => {
            this.config.refresh()
          }
        })
      },
      refreshStatus: function () {
        this.statusLoading = true
        this.message = 'Confirmando QR Code, por favor aguarde!'
        this.$WiApiGet({
          uri: `talks/channel/${this.channel.id}/connected`,
          callback: () => {
            this.config.refresh()
            this.statusLoading = false
            console.log(this.channel, 'channel')
          }
        })
      },
      updateGroups: function () {
        this.statusLoading = true
        this.$WiApiPut({
          uri: `talks/channel/${this.channel.id}/update-all-groups`,
          config: {msgAsk: true, msgLoad: true, msgSucess: true, msgError: true},
          callback: () => {
            this.config.refresh()
            this.statusLoading = false
          }
        })
      }
    },
    props: {
      config: Object,
      props: Object
    },
    components: {
      WhatsAppQRCode
    }
  }
</script>
<style>
  td {
    padding: 0 5px !important;
  }
  th {
    padding: 0 5px !important;
  }
</style>